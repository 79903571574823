import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submit"]
  static values = {}

  validate(event) {
    let comment_text = event.target.value
    let default_value = event.target.dataset.defaultValue

    if (comment_text.length === 0 || comment_text === default_value) {
      this.submitTarget.disabled = true
    } else {
      this.submitTarget.disabled = false
    }
  }
}
