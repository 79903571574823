import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "continueButton", "continueForm", "citizenshipButton", "destinationButton", "secondFieldset" ]
  static values = {
    citizenship: String,
    destination: String,
    checked: String,
    notChecked: String
  }

  selectCitizenship(event) {
    this.citizenshipButtonTargets.forEach((target) => {
      target.parentElement.classList.remove(...this.checkedValue.split(" "))
      target.parentElement.classList.add(...this.notCheckedValue.split(" "))
    })
    event.target.parentElement.classList.remove(...this.notCheckedValue.split(" "))
    event.target.parentElement.classList.add(...this.checkedValue.split(" "))
    this.citizenshipValue = event.params.citizenship
    this.secondFieldsetTarget.disabled = false
    this._resetForm()

    let destinations = relocation_destinations[event.params.citizenship].destinations
    this.destinationButtonTargets.forEach((target) => {
      if (destinations.includes(target.value)) {
        target.parentElement.classList.remove("hidden")
      } else {
        target.parentElement.classList.add("hidden")
      }
    })
  }

  selectDestination(event) {
    this.destinationButtonTargets.forEach((target) => {
      target.parentElement.classList.remove(...this.checkedValue.split(" "))
      target.parentElement.classList.add(...this.notCheckedValue.split(" "))
    })
    event.target.parentElement.classList.remove(...this.notCheckedValue.split(" "))
    event.target.parentElement.classList.add(...this.checkedValue.split(" "))
    this.destinationValue = event.params.destination
    this._continueButtonHandler()
  }

  _continueButtonHandler() {
    if (this.citizenshipValue.length > 0 && this.destinationValue.length > 0) {
      this.continueFormTarget.action = "/r/" + this.citizenshipValue + "/" + this.destinationValue
      this.continueButtonTarget.disabled = false
    }
  }

  _resetForm() {
    if (this.citizenshipValue.length > 0 && this.destinationValue.length > 0) {
      this.destinationButtonTargets.forEach((target) => {
        target.parentElement.classList.remove(...this.checkedValue.split(" "))
        target.parentElement.classList.add(...this.notCheckedValue.split(" "))
      })
      this.destinationValue = ""
      this.continueButtonTarget.disabled = true
    }
  }
}
